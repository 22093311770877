
  import { Vue, Component } from "vue-property-decorator";
  import dayjs from "dayjs";
  import { getbindNum , searchBind ,getMachineQrcode,deleteMachineNum,bindInfo} from "@/api/modules/getmachine";
  @Component({
    name: "bind",
  })
  export default class bind extends Vue {
    private tableData: Array<any> = [];
    private pageNo = 1;
    private pageSize = 10;
    private form = {search:""};
    private queryparam = "";
    
  
    private async mounted() {
    //   let datas = [];
    //   for (let i = 0; i < 400; i++) {
    //     datas.push({
    //       date: dayjs().format("YYYY-MM-DD"),
    //       name: `姓名${i}`,
    //       address: `地址${i}`,
    //     });
    //   }
    //   this.tableData = datas;
      let res =await getbindNum(1)
      this.tableData = res.list;
      this.pageSize = res.total
      //this.pageNo = res.page_count
      console.log("默认第一页",res)
    }
  
    private handleSizeChange(val: number) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.pageNo = 1;
    }
  
    private handleCurrentChange(val: number) {
      this.pageNo = val;
      console.log(`当前页: ${val}`);
      let str = this.queryparam?"&search="+this.queryparam:""
      let param = val+ str
      console.log(`当前字符串: ${param}`);
       getbindNum((param)).then((res)=>{
        this.tableData = res.list;
        this.pageSize = res.total
       })
       
    }
  
    //审核
    private async isBind(id:string,issave:boolean){
      let res1 = await bindInfo({
          "id":id,
          "is_save":issave
        })
        //刷新第一页
        this.pageNo =1
        let str = this.queryparam?"&search="+this.queryparam:""
      let param = this.pageNo+ str
        let res =await getbindNum(param)
        this.tableData = res.list;
        this.pageSize = res.total
        console.log("res1",res1)
        if(res1){
          this.$message({
            type: 'success',
            message: '审核成功!'
          });
        }else{
          this.$message({
            type: 'info',
            message: '审核失败请联系后台！'
          });     
        }
    }

    private  open(id:string) {
        this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
        let res1 = await deleteMachineNum(id)
        //刷新第一页
        this.pageNo =1
        let res =await getbindNum(this.pageNo+this.queryparam?"":"&search="+this.queryparam)
        this.tableData = res.list;
        this.pageSize = res.total
        if(res1.code==200){
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }else{
          this.$message({
            type: 'info',
            message: '删除失败请联系后台！'
          });     
        }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      }
    

    private async onSubmit() {
      this.pageNo = 1
      console.log(this.form);
      let res =await searchBind(this.form.search.match(/^[ ]*$/)?"":(this.form.search));
      this.queryparam = this.form.search.match(/^[ ]*$/)?"":this.form.search
      console.log(res);
      this.tableData = res.list;
      this.pageSize = res.total
    }
  }
  